import { Popover} from "@headlessui/react"
// import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Logo } from "../app/logo"
import { MenuIcon, PhoneCallIcon, X } from "lucide-react"
import { Banner } from "./banner"

interface HeroProps {
  imgSrc: string
  handleClick: (event: any, documentElementId: string) => void
}

const navigation = [
  { name: "Work", documentElementId: "work" },
  { name: "Reviews", documentElementId: "reviews" },
  { name: "Team", documentElementId: "team" },
]

export const PHONE_NUMBER = "(201) 749-2034"

// Main header with navigation for the landing page
export function Header({ handleClick }: { handleClick: (event: any, documentElementId: string) => void }) {
  return (
    <Popover className="sticky top-0 z-50 bg-sapphire-200">
      <div className="relative">
        <Banner />
        <div className="py-4 px-4 sm:px-6 lg:px-8">
          <nav className="relative flex items-center justify-between sm:h-10" aria-label="Global">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/" className="flex flex-shrink-0 items-center">
                  <img className="block h-12 mr-2" src="/img/logo.png" alt="Dr. Drip Plumbing Logo" />
                </a>

                <div className="flex items-center md:hidden space-x-4">
                  <p className="font-semibold text-lg text-sapphire-900">
                    <a className="flex" href={`tel:+1${PHONE_NUMBER}`}>
                      <PhoneCallIcon className="mx-2" />
                      {PHONE_NUMBER}
                    </a>
                  </p>

                  <Popover.Button className="bg-sapphire-100 rounded-md p-2 inline-flex items-center justify-center">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>

            <div className="hidden md:flex md:items-center md:space-x-8">
              {navigation.map(item => (
                <button
                  key={item.name}
                  onClick={event => handleClick(event, item.documentElementId)}
                  className="text-sapphire-900 hover:cursor-pointer">
                  {item.name}
                </button>
              ))}
            </div>
          </nav>
        </div>
      </div>

      <Popover.Panel className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div className="rounded-lg shadow-md bg-sapphire-300 ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <Logo />
            <div className="-mr-2">
              <Popover.Button className="bg-sapphire-100 rounded-md p-2 inline-flex items-center justify-center">
                <span className="sr-only">Close main menu</span>
                <X className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navigation.map(item => (
              <button
                key={item.name}
                onClick={event => handleClick(event, item.documentElementId)}
                className="block w-full px-3 py-2 rounded-md text-base font-medium text-sapphire-900 hover:bg-sapphire-200">
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

// Simple header for location pages
export function SimpleHeader() {
  return (
    <header className="sticky top-0 z-50 bg-sapphire-200">
      <Banner />
      <div className="py-4 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between sm:h-10">
          <a href="/" className="flex flex-shrink-0 items-center">
            <img className="block h-12 mr-2" src="/img/logo.png" alt="Dr. Drip Plumbing Logo" />
          </a>
          
          <a
            href={`tel:+1${PHONE_NUMBER}`}
            className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md btn btn-primary">
            <PhoneCallIcon className="mr-2" />
            Call {PHONE_NUMBER}
          </a>
        </nav>
      </div>
    </header>
  )
}

export function Hero({ handleClick, imgSrc }: HeroProps) {
  return (
    <div className=" bg-sapphire-100">
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 py-8 bg-sapphire-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-24 text-sapphire-900 transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true">
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl tracking-tight font-extrabold text-sapphire-900 sm:text-4xl md:text-5xl">
                  <span className="block xl:inline">Plumbing and service that feels like</span>{" "}
                  <span className="block text-sapphire-500 xl:inline">family</span>
                </h1>
                <p className="mt-3 text-base text-sapphire-900 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  {/* At Dr drip plumbing and heating, we are a veteran owned and operated team of highly trained professionals. A family run organization with a primary goal of bringing comfort to your family’s home  */}
                  We are a family-run and veteran-owned organization of highly trained professionals
                  with a primary goal of bringing comfort to your family’s home.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href={`tel:+1${PHONE_NUMBER}`}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md btn btn-primary md:py-4 md:text-lg md:px-10">
                      <PhoneCallIcon className="mr-2" />
                      Call {PHONE_NUMBER}
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 flex items-center justify-center relative">
          <div className="absolute inset-0 bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply z-10"></div>
          <img
            className="w-full h-auto lg:w-full lg:h-full object-contain z-0"
            src={imgSrc}
            alt=""
            style={{ willChange: 'transform' }}
          />
        </div> */}

        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-white flex items-center justify-center">
          <img
            className="pl-3 lg:pl-24 w-full h-auto lg:w-full lg:h-full object-contain"
            src={imgSrc}
            alt="Dr. Drip Plumbing Logo"
            style={{ willChange: 'transform' }}
          />
        </div>
        {/* size is good */}
        {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply flex items-center justify-center">
          <img
            className="pl-16 w-full h-auto lg:w-full lg:h-full object-contain"
            src={imgSrc}
            alt=""
            style={{ willChange: 'transform' }}
          />
        </div> */}


        {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply flex items-center justify-center">
          <img
            className="w-full h-full lg:w-full lg:h-full object-cover"
            src={imgSrc}
            alt=""
            style={{ willChange: 'transform' }}
          />
        </div> */}

        {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply flex items-center justify-center">
          <img
            className="w-full h-auto lg:h-full object-cover lg:object-contain lg:aspect-[16/9] lg:min-w-[350px] lg:min-h-[350px] transform-gpu"
            src={imgSrc}
            alt=""
            style={{ willChange: 'transform' }} // Optional: forces GPU acceleration
          />
        </div> */}
        
        {/* not working on safari */}
        {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2  bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply  flex items-center justify-center">
          <img
            className=" w-fit object-cover lg:w-fit lg:h-fit bg-gradient-to-r from-sapphire-400 to-sapphire-300 mix-blend-multiply lg:transform lg:scale-75 lg:mt-5 lg:ml-32"
            src={imgSrc}
            // src="https://images.unsplash.com/photo-1565936795196-4f283b0ae59b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNTI4Mzh8MHwxfGFsbHx8fHx8fHx8fDE2NjAxMzMxMzA&ixlib=rb-1.2.1&q=80&w=1080"
            // src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
            alt=""
          />
        </div> */}
      </div>
    </div>
  )
}
