type ServiceAreaDetails = {
  name: string;
  urlPath: string;
  county: string;
  description: string;
  center: { lat: number; lng: number };
}

export const SERVICE_AREAS: ServiceAreaDetails[] = [
  {
    name: "River Vale",
    urlPath: "river-vale",
    county: "Bergen County - Pascack Valley",
    description: "As your local plumbing experts, we're proud to serve the River Vale community with comprehensive plumbing and heating services.",
    center: { lat: 41.0123, lng: -74.0087 },
  },
  {
    name: "Westwood",
    urlPath: "westwood",
    county: "Bergen County - Pascack Valley",
    description: "Serving Westwood residents and businesses with reliable plumbing and heating solutions.",
    center: { lat: 40.9912, lng: -74.0324 },
  },
  {
    name: "Hillsdale",
    urlPath: "hillsdale",
    county: "Bergen County - Pascack Valley",
    description: "Your trusted local plumber serving the Hillsdale community.",
    center: { lat: 41.0062, lng: -74.0446 },
  },
  {
    name: "Montvale",
    urlPath: "montvale",
    county: "Bergen County - Pascack Valley",
    description: "Professional plumbing services for the Montvale community.",
    center: { lat: 41.0537, lng: -74.0429 },
  },
  {
    name: "Woodcliff Lake",
    urlPath: "woodcliff-lake",
    county: "Bergen County - Pascack Valley",
    description: "Expert plumbing and heating services for Woodcliff Lake residents.",
    center: { lat: 41.0262, lng: -74.0543 },
  },
  {
    name: "Park Ridge",
    urlPath: "park-ridge",
    county: "Bergen County - Pascack Valley",
    description: "Comprehensive plumbing solutions for Park Ridge homes and businesses.",
    center: { lat: 41.0362, lng: -74.0429 },
  },
  {
    name: "Old Tappan",
    urlPath: "old-tappan",
    county: "Bergen County - Northern Valley",
    description: "Reliable plumbing services for the Old Tappan community.",
    center: { lat: 41.0126, lng: -73.9901 },
  },
  {
    name: "Emerson",
    urlPath: "emerson",
    county: "Bergen County - Pascack Valley",
    description: "Quality plumbing and heating services in Emerson.",
    center: { lat: 40.9762, lng: -74.0279 },
  },
  {
    name: "Closter",
    urlPath: "closter",
    county: "Bergen County - Northern Valley",
    description: "Professional plumbing solutions for Closter residents.",
    center: { lat: 40.9712, lng: -73.9615 },
  },
  {
    name: "Harrington Park",
    urlPath: "harrington-park",
    county: "Bergen County - Northern Valley",
    description: "Trusted plumbing services in Harrington Park.",
    center: { lat: 40.9887, lng: -73.9762 },
  },
  {
    name: "Northvale",
    urlPath: "northvale",
    county: "Bergen County - Northern Valley",
    description: "Expert plumbing care for Northvale homes and businesses.",
    center: { lat: 41.0126, lng: -73.9462 },
  },
  {
    name: "Norwood",
    urlPath: "norwood",
    county: "Bergen County - Northern Valley",
    description: "Complete plumbing and heating solutions in Norwood.",
    center: { lat: 40.9943, lng: -73.9479 },
  },
  {
    name: "Alpine",
    urlPath: "alpine",
    county: "Bergen County - Northern Valley",
    description: "Premium plumbing services for Alpine residents.",
    center: { lat: 40.9598, lng: -73.9268 },
  },
  {
    name: "Cresskill",
    urlPath: "cresskill",
    county: "Bergen County - Northern Valley",
    description: "Professional plumbing expertise in Cresskill.",
    center: { lat: 40.9387, lng: -73.9618 },
  },
  {
    name: "Tenafly",
    urlPath: "tenafly",
    county: "Bergen County - Northern Valley",
    description: "Comprehensive plumbing services for Tenafly.",
    center: { lat: 40.9198, lng: -73.9496 },
  },
  {
    name: "Haworth",
    urlPath: "haworth",
    county: "Bergen County - Northern Valley",
    description: "Reliable plumbing solutions in Haworth.",
    center: { lat: 40.9645, lng: -73.9901 },
  },
  {
    name: "Saddle River",
    urlPath: "saddle-river",
    county: "Bergen County - Northwest",
    description: "Expert plumbing and heating services in Saddle River.",
    center: { lat: 41.0265, lng: -74.0968 },
  },
  {
    name: "Upper Saddle River",
    urlPath: "upper-saddle-river",
    county: "Bergen County - Northwest",
    description: "Professional plumbing care for Upper Saddle River residents.",
    center: { lat: 41.0637, lng: -74.0996 },
  },
  {
    name: "Mahwah",
    urlPath: "mahwah",
    county: "Bergen County - Northwest",
    description: "Comprehensive plumbing solutions in Mahwah.",
    center: { lat: 41.0887, lng: -74.1435 },
  },
  {
    name: "Ramsey",
    urlPath: "ramsey",
    county: "Bergen County - Northwest",
    description: "Quality plumbing services for Ramsey homes and businesses.",
    center: { lat: 41.0573, lng: -74.1435 },
  },
  {
    name: "Ho-Ho-Kus",
    urlPath: "ho-ho-kus",
    county: "Bergen County - Northwest",
    description: "Expert plumbing care in Ho-Ho-Kus.",
    center: { lat: 40.9998, lng: -74.1018 },
  },
  {
    name: "Franklin Lakes",
    urlPath: "franklin-lakes",
    county: "Bergen County - Northwest",
    description: "Professional plumbing solutions for Franklin Lakes.",
    center: { lat: 41.0167, lng: -74.2151 },
  },
  {
    name: "Oakland",
    urlPath: "oakland",
    county: "Bergen County - Northwest",
    description: "Reliable plumbing services in Oakland.",
    center: { lat: 41.0331, lng: -74.2415 },
  },
  {
    name: "Wyckoff",
    urlPath: "wyckoff",
    county: "Bergen County - Northwest",
    description: "Trusted plumbing expertise for Wyckoff residents.",
    center: { lat: 40.9987, lng: -74.1715 },
  },
  {
    name: "Ridgewood",
    urlPath: "ridgewood",
    county: "Bergen County - Northwest",
    description: "Complete plumbing and heating solutions in Ridgewood.",
    center: { lat: 40.9793, lng: -74.1165 },
  },
  {
    name: "Paramus",
    urlPath: "paramus",
    county: "Bergen County - Central",
    description: "Professional plumbing services in Paramus.",
    center: { lat: 40.9445, lng: -74.0715 },
  },
  {
    name: "River Edge",
    urlPath: "river-edge",
    county: "Bergen County - Central",
    description: "Expert plumbing care for River Edge homes.",
    center: { lat: 40.9276, lng: -74.0379 },
  },
  {
    name: "Oradell",
    urlPath: "oradell",
    county: "Bergen County - Central",
    description: "Quality plumbing solutions in Oradell.",
    center: { lat: 40.9565, lng: -74.0329 },
  },
  {
    name: "Bergenfield",
    urlPath: "bergenfield",
    county: "Bergen County - Central",
    description: "Comprehensive plumbing services for Bergenfield.",
    center: { lat: 40.9276, lng: -73.9993 },
  },
  {
    name: "Dumont",
    urlPath: "dumont",
    county: "Bergen County - Central",
    description: "Reliable plumbing expertise in Dumont.",
    center: { lat: 40.9445, lng: -73.9918 },
  },
  // Rockland County
  {
    name: "Tappan",
    urlPath: "tappan",
    county: "Rockland County",
    description: "Professional plumbing services in Tappan, NY.",
    center: { lat: 41.0231, lng: -73.9493 },
  },
  {
    name: "New City",
    urlPath: "new-city",
    county: "Rockland County",
    description: "Expert plumbing solutions for New City residents.",
    center: { lat: 41.1476, lng: -73.9893 },
  },
  {
    name: "Suffern",
    urlPath: "suffern",
    county: "Rockland County",
    description: "Quality plumbing care in Suffern.",
    center: { lat: 41.1151, lng: -74.1496 },
  },
  {
    name: "Sloatsburg",
    urlPath: "sloatsburg",
    county: "Rockland County",
    description: "Trusted plumbing services for Sloatsburg.",
    center: { lat: 41.1573, lng: -74.1893 },
  },
  {
    name: "Pearl River",
    urlPath: "pearl-river",
    county: "Rockland County",
    description: "Comprehensive plumbing solutions in Pearl River.",
    center: { lat: 41.0593, lng: -74.0218 },
  },
  {
    name: "Nanuet",
    urlPath: "nanuet",
    county: "Rockland County",
    description: "Professional plumbing expertise for Nanuet homes.",
    center: { lat: 41.0962, lng: -74.0129 },
  },
  {
    name: "Valley Cottage",
    urlPath: "valley-cottage",
    county: "Rockland County",
    description: "Quality plumbing services in Valley Cottage.",
    center: { lat: 41.1262, lng: -73.9429 },
  },
  {
    name: "West Nyack",
    urlPath: "west-nyack",
    county: "Rockland County",
    description: "Expert plumbing care for West Nyack residents.",
    center: { lat: 41.0962, lng: -73.9729 },
  },
  {
    name: "Orangeburg",
    urlPath: "orangeburg",
    county: "Rockland County",
    description: "Reliable plumbing solutions in Orangeburg.",
    center: { lat: 41.0462, lng: -73.9529 },
  }
];

// Keep existing TOWNS_BY_COUNTY organization
export const TOWNS_BY_COUNTY = {
  "Bergen County - Pascack Valley": [
    "River Vale", "Westwood", "Hillsdale", "Montvale",
    "Woodcliff Lake", "Park Ridge", "Old Tappan", "Emerson"
  ],
  "Bergen County - Northern Valley": [
    "Closter", "Harrington Park", "Northvale", "Norwood",
    "Alpine", "Cresskill", "Tenafly", "Haworth"
  ],
  "Bergen County - Northwest": [
    "Mahwah", "Ramsey", "Upper Saddle River", "Saddle River",
    "Ho-Ho-Kus", "Ridgewood", "Franklin Lakes", "Oakland", "Wyckoff"
  ],
  "Bergen County - Central": [
    "Paramus", "River Edge", "Oradell", "Bergenfield", "Dumont"
  ],
  "Rockland County": [
    "Tappan", "New City", "Suffern", "Sloatsburg",
    "Pearl River", "Nanuet", "Valley Cottage", "West Nyack", "Orangeburg"
  ]
}; 