import {Route, Switch} from "wouter-preact"
import {Landing} from "../landing/landing.tsx"
import { LocationPage } from "../components/location-page.tsx";
import { SERVICE_AREAS } from "../data/service-areas"

export function Routes() {
  return (
    <Switch>
      <Route path="/" component={Landing}/>
      {SERVICE_AREAS.map(location => (
        <Route 
          key={location.urlPath}
          path={`/locations/${location.urlPath}`}
          component={() => <LocationPage location={location} />}
        />
      ))}
    </Switch>
  )
}
