import { Badge } from "@/components/ui/badge"
import { classes } from "../utils/util"
import { WORK } from "./work"
import { SERVICE_AREAS, TOWNS_BY_COUNTY } from "../data/service-areas"

export function Interlude({
  badgeCopy,
  title,
  description,
  className,
}: {
  badgeCopy: string
  title: string
  description: string
  className?: string
}) {
  return (
    <div className={classes("bg-sapphire-100 flex flex-col items-center justify-center px-4 text-sapphire-900", className)}>
      <Badge variant="secondary" className="mb-8">
        {badgeCopy}
      </Badge>

      <h1 className="text-5xl font-bold text-center mb-4">{title}</h1>

      <p className="text-xl text-center text-sapphire-700 mb-16 max-w-2xl">{description}</p>
    </div>
  )
}

export function Footer() {
  return (
    <footer className="bg-sapphire-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          <div className="md:col-span-3 space-y-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <p>524 Alosio Dr</p>
              <p>River Vale, NJ 07675</p>
              <p>info@drdripplumbing.com</p>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold mb-4">Hours</h3>
              <p>Monday - Friday: 8am - 6pm</p>
              <p>Saturday: 8am - 4pm</p>
              <p>Sunday: Closed</p>
              <p className="mt-2 text-sapphire-300">24/7 Emergency Service Available</p>
            </div>
          </div>

          <div className="md:col-span-3">
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              {WORK.map((work) => (
                <li key={work.title}>{work.title}</li>
              ))}
            </ul>
          </div>

          <div className="md:col-span-6">
            <h3 className="text-lg font-semibold mb-4">Service Areas</h3>
            <div className="grid grid-cols-2 gap-x-8">
              {Object.entries(TOWNS_BY_COUNTY).map(([county, towns]) => (
                <div key={county} className="mb-6">
                  <h4 className="text-sapphire-300 text-sm font-medium mb-2">{county}</h4>
                  <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                    {towns.map(town => {
                      const area = SERVICE_AREAS.find(a => a.name === town);
                      return (
                        <div key={town}>
                          {area ? (
                            <a 
                              href={`/locations/${area.urlPath}`}
                              className="hover:text-sapphire-300 transition-colors text-sm"
                            >
                              {town}
                            </a>
                          ) : (
                            <span className="text-sm">{town}</span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="border sm:rounded-2xl border-sapphire-600 bg-sapphire-300 mt-8">
          <div className="flex items-center justify-center p-2">
            <a href="/" className="flex items-center space-x-3">
              <img
                class={`block h-24 mr-2`}
                src="/img/logo.png"
                alt="Dr. Drip"
              />
            </a>
          </div>
          <div class="flex flex-col items-center justify-center my-2">
            <p className="text-sapphire-900 mb-4">Rivervale, NJ 07675</p>
            <p className="text-sapphire-900">NJMP #: 36BI01371200</p>
            <p className="text-sapphire-900">NJHIC #: 13VH13069000</p>
            <p className="text-sapphire-900 mb-4">Rockland County License #: P-24-281</p>
            <footer className="text-sapphire-900">{`© Dr. Drip ${new Date().getUTCFullYear()}. All rights reserved.`}</footer>
          </div>
        </div>
      </div>
    </footer>
  )
}
